import { shuffleArray, WEB_URL } from '@centered/helpers'
import { Group } from '@centered/types'
import { useMemo } from 'react'
import SEOMetaTags from '../seo/SEOMetaTags'
import NewFooter from '../shared/NewFooter'
import Header from '../shared/Header'
import AICoaches from './AICoaches'
import AvailableIntegrations from './AvailableIntegrations'
import BuddySessions from './BuddySessions'
import Burnout from './Burnout'
import Difference from './Difference'
import FasterClaims from './FasterClaims'
import FewMoreThings from './FewMoreThings'
import HeroSection from './HeroSection'
import HowToSection from './HowToSection'
import HowItWorks from './HowItWorks'
import LaserFocus from './LaserFocus'
import MusicModule from './MusicModule'
import OrgUsers from './OrgUsers'
import PhoneDistraction from './PhoneDistraction'
import Pricing from './Pricing'
import QuoteModule from './QuoteModule'
import { quotes } from './quotes'
import ScoresModule from './ScoresModule'
import TaskModule from './TasksModule'
import TheSolutionToYourProblem from './TheSolutionToYourProblem'
import VideoWalkthrough from './VideoWalkthrough'
import WhyCreateThis from './WhyCreateThis'

interface LandingPageProps {
  featuredGroups: Group[]
}

export default function LandingPage({
  featuredGroups,
}: LandingPageProps): JSX.Element {
  const shuffledGroups = useMemo(() => {
    return shuffleArray(featuredGroups)
  }, [featuredGroups])

  const script = `
    var _avp = _avp || [];
    (function() {
      var s = document.createElement('script');
      s.type = 'text/javascript'; s.async = true; s.src = 'https://portal.smartrecognition.com/js/libcode3.js';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })();
  `;

  const divScript = `
    var _avp = _avp || [];
    if (!document.cookie || document.cookie.indexOf('AVPDCAP=') == -1) { 
      _avp.push({ tagid: '9wlUguO64fMMDvO4wiiU', alias: '/', type: 'dynamic', zid: 7723, pid: 5234, secure: true });
    }
  `;
  

  return (
    <main className="overflow-x-hidden">
      <style jsx global>{`
        body {
          background-color: #000000;
          color: white;
        }
      `}</style>

      <SEOMetaTags
        title="Sukha – Focused Co-Working"
        imageUrl={`${WEB_URL}/og-main-v2.png`}
      />

      <Header alwaysShowSignupButton={false} v7 />

      <HeroSection />

      <QuoteModule quote={quotes.beth} />

      <TheSolutionToYourProblem />  

      <HowToSection />
      
      <HowItWorks />

      <QuoteModule quote={quotes.marshall} reverse />

      <div className="w-full space-y-40 py-28">
        <VideoWalkthrough />
      </div>      
      
      <QuoteModule quote={quotes.kent} />

      <WhyCreateThis />

      <QuoteModule quote={quotes.matt} reverse />

      <div className="w-full space-y-40 pt-28 mb-10">
        <FasterClaims />
      </div>
      
      <Pricing />

      <QuoteModule quote={quotes.wagner} />   

      <OrgUsers />

      <LaserFocus />

      <QuoteModule quote={quotes.martin} reverse />

      <NewFooter />
      <script dangerouslySetInnerHTML={{ __html: script }} />
      <div data-tagid="9wlUguO64fMMDvO4wiiU">
        <script dangerouslySetInnerHTML={{ __html: divScript }} />
      </div>
    </main>
  )
}
