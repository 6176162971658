import Image from 'next/image'
import Wrapper from '../shared/Wrapper'
import ModuleImage from './assets/2x-faster.jpg'

export default function FasterClaims(): JSX.Element {
  return (
    <Wrapper>
      <div className="flex flex-col-reverse md:flex-row gap-10 items-center justify-between">
        <div className="flex-1 max-w-xl">
          <h2 className="text-2xl md:text-4xl font-bold leading-normal mb-20">
            Members finish their work<br /> 2x faster.
          </h2>

          <p className="text-lg md:text-2xl">
            Research shows we lose half our day to distractions & burnout.
            <br />
            <br />
            With 400,000+ tasks now completed, Sukha members finish 2x faster
            than their estimates.
            <br />
            <br />
            Try it for free and go to bed tonight feeling relaxed for a change.
          </p>
        </div>

        <Image
          src={ModuleImage}
          alt="female worker focusing on being productive"
          width={500}
          height={500}
          style={{
            borderRadius: 48,
          }}
          className="bg-black"
        />
      </div>
    </Wrapper>
  )
}
