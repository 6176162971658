import { StaticImageData } from 'next/image'

import KentAvatar from './assets/kent-quote-avatar.jpg'
import KentBg from './assets/kent-quote-bg.jpg'
import LennyAvatar from './assets/lenny-quote-avatar.jpg'
import LennyBg from './assets/lenny-quote-bg.jpg'
import PKMBethAvatar from './assets/PKMBeth-headshot.png'
import MarshallAvatar from './assets/Marshall-over-grey.jpg'
import MarshallBg from './assets/marshall-quote-bg.jpg'
import MartinAvatar from './assets/martin-quote-avatar.jpg'
import MartinBg from './assets/martin-quote-bg.jpg'
import Matt from './assets/Matt-over-white.jpg'
import WagnerAvatar from './assets/wagner-quote-avatar.jpg'
import WagnerBg from './assets/wagner-quote-bg.jpg'

export type Quote = {
  avatar: StaticImageData
  background: StaticImageData
  name: string
  handle: string
  quote: JSX.Element
}

export const quotes = {
  lenny: {
    avatar: LennyAvatar,
    background: LennyBg,
    name: 'Lenny Rachitsky',
    handle: '#1 Substack for Business',
    quote: (
      <>
        A game changer! <br /> I got more done in the last 3 hours than I did
        all of yesterday!
      </>
    ),
  },
  martin: {
    avatar: MartinAvatar,
    background: MartinBg,
    name: 'Martin Slaney',
    handle: '5x Founder & Author',
    quote: (
      <>
        Product Of The Year - by a mile.
        <br />A new age of productivity has begun.
      </>
    ),
  },
  beth: {
    avatar: PKMBethAvatar,
    background: MartinBg,
    name: 'PKMBeth',
    handle: 'Medium writer',
    quote: (
      <>
        My morning Sukha session is the highlight of my day.&nbsp;&nbsp; 
        <b>It’s the single greatest app I’ve found for my productivity….</b>
      </>
    ),
  },
  marshall: {
    avatar: MarshallAvatar,
    background: MarshallBg,
    name: 'Marshall Kirkpatrick',
    handle: 'Sustainability Consultant',
    quote: (
      <>
        Wow this is the coolest productivity app <br />
        I’ve tried in a long, long time. <br />
        It’s like a dream come true.
      </>
    ),
  },

  wagner: {
    avatar: WagnerAvatar,
    background: WagnerBg,
    name: 'Wagner',
    handle: 'UI/UX Designer',
    quote: (
      <>
        This app really gets every feature you need to focus / get in the flow
        in one place and it does it very well. &nbsp;The social aspect and also
        the human voice are huge features, I love it!
      </>
    ),
  },

  matt: {
    avatar: Matt,
    background: KentBg,
    name: 'Matt Evans',
    handle: 'Award-winning Webflow Developer',
    quote: (
      <>
        As a person with ADD, I can say one of <br />
        the best investments I have made in <br />
        my ability to stay productive is this app.
      </>
    ),
  },

  kent: {
    avatar: KentAvatar,
    background: KentBg,
    name: 'Kent C. Dodds',
    handle: 'Founder, EpicWeb.dev',
    quote: (
      <>
        Just so much to get done for the @remix_run launch on Monday. <br /> I
        could never have gotten all this done without it. <br />
        This thing is amazing.
      </>
    ),
  },
} as const
