import Image from 'next/image'
import TryCenteredButton from './TryCenteredButton'
import Background from './assets/Runners-darkened-bg.png'

export default function LaserFocus(): JSX.Element {
  return (
    <div className="relative w-full py-36 bg-black">
      <Image
        src={Background}
        fill
        role="presentation"
        alt=""
        className="object-cover object-center"
      />

      <div className="text-center relative z-20 px-4 text-white">
        <h2 className="font-bold text-4xl mb-4">Why did we create this?</h2>
        <p className="text-2xl mb-14">
        <br />
          There is a reason why Nike sells individual pairs of shoes, but runners create running clubs.<br />
          <br />
          There is a reason the Navy SEALS train individually, but go on their hardest missions as a team.<br />
          <br />
          There is a reason musicians can succeed alone, but the biggest musical acts in the world are bands.<br />
          <br />
          <i>"If you want to go fast, go alone.<br/>
          If you want to go far, go together."</i><br/>
          <br />
          I wish you great journeys.<br />
          <br />
          - Steven Puri, Founder & CEO
        </p>

      </div>
    </div>
  )
}
