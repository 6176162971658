import Image from 'next/image'
import TryCenteredButton from './TryCenteredButton'
import Background from './assets/lightspeed1.jpeg'

export default function LaserFocus(): JSX.Element {
  return (
    <div className="relative w-full py-36 bg-black">
      <Image
        src={Background}
        fill
        role="presentation"
        alt=""
        className="object-cover object-center"
      />

      <div className="text-center relative z-20 px-4 text-white">
        <h2 className="font-bold text-4xl mb-4">
          Sick of that feeling at 6pm when your work's not done?<br/>
          <br/>
          Here's how Sukha ends that.
        </h2>
{/*}
         <p className="text-2xl mb-14">
          Being able to see others <b>boosts not just your productivity</b>
          <br />
          (and therefore your income) <br />
          but also your sense of <b>happiness & community.</b>
        </p>
*/}
      </div>
    </div>
  )
}
